import React, { useEffect, useState } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loading from '../components/Loading';

function MySubscription() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMySubscriptions = async () => {

      const captainData = getItemFromLocalStorage('captainProfile');
      let formData = new FormData();
      formData.append('token', captainData.captain_access_token);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.mySubscriptions}`,
          data: formData
        });

        if (response.data.items && Array.isArray(response.data.items)) {
          console.log(response.data);
          setData(response.data.items);
        } else {
          setError(response.message || 'Error in fetching data.');
        }
      } catch (error) {
        return <div>
          <div className='text-center text-uppercase'>
              <img src='/assets/img/no_subscription_found.png' alt='' style={{width:'300px'}} className='img-fluid' />
              <h4>SUBSCRIPTION LOADING ERROR</h4>
              <p>We’re currently experiencing an issue fetching your subscriptions. Please try again later while we work to resolve the problem. Thank you for your patience!</p>
            </div>
        </div>
      } finally {
        setLoading(false);
      }
    };

    fetchMySubscriptions();
  }, []);

  if (loading) {
    return <div><Loading /></div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div className='ms-section'>
      {data.length > 0 ? (<>
        {data.map(item => (
            <div className='ms-box'>
                <div className='ms-box-header'>
                    <h4>{item.subscription_name} -
                      <span className='ms-price-title'> {item.subscription_final_rate_display} / {item.subscription_tenure_display}</span>
                    </h4>                               
                    <div className='ms-body-flex-item'>
                        <p className={`ms-purchase-status ${item.subscription_status === 2 ? 'active' : 'inactive'}`}>{item.subscription_status_display}</p>
                    </div>
                </div>
                <div className='ms-box-body'>
                    <div className="row ms-features">
                        <h5 className='ms-price-title'>Features</h5>
                        {item.subscription_features.map(feature => (
                            <div className="col-md-3 ms-feature">
                                <div className="ms-feature-point">
                                    {feature.feature_code === 'TAGPHOTO' && (
                                      <img src='/assets/img/icons/fs_post_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'TAGREVIEW' && (
                                      <img src='/assets/img/icons/fs_tagged_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'LISTING' && (
                                      <img src='/assets/img/icons/fs_add_buss_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'BANNER' && (
                                      <img src='/assets/img/icons/fs_banner_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'TABLE' && (
                                      <img src='/assets/img/icons/fs_side_table_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'MITEM' && (
                                      <img src='/assets/img/icons/fs_side_menu_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'OITEM' && (
                                      <img src='/assets/img/icons/fs_side_offer_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'PUSH' && (
                                      <img src='/assets/img/icons/fs_push_active.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'CWPL' && (
                                      <img src='/assets/img/icons/fs_crowd_puller_icon.svg' alt='' className='subs-icon' />
                                    )}
                                    {feature.feature_code === 'NOAD' && (
                                      <img src='/assets/img/icons/fs_no_adv.svg' alt='' className='subs-icon' />
                                    )}
                                    <div className='mspfts'>
                                      <p>{feature.feature_title}</p>
                                      <span>{feature.usage}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='ms-box-footer'>
                    <div className='ms-body-flex-item'>
                        <p>Purchased on : {item.subscription_purchased_on}</p>
                        <p>Valid till : {item.subscription_valid_till_display}</p>
                    </div>
                </div>
            </div>
        ))}
        </>) : (<>
            <div className='text-center text-uppercase'>
              <img src='/assets/img/no_subscription_found.png' alt='' style={{width:'300px'}} className='img-fluid' />
              <h4>NO ACTIVE SUBSCRIPTION OR UNAVAILABLE IN YOUR CITY</h4>
              <p>It appears you have no active subscriptions at the moment! This could be due to not having purchased one yet or because there are currently no active subscriptions available in your city.</p>
              <p>To activate a subscription, please consider making a purchase. Alternatively, you can wait for new subscriptions to become available, and we’ll notify you as soon as they do. If you need assistance, don’t hesitate to reach out to our team!</p>
            </div>
        </>)}
      </div>
    </div>
  )
}

export default MySubscription;