import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getItemFromLocalStorage } from '../utils/storageUtils';

const Default = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const sessionCaptainId = sessionStorage.getItem('captain_id');
    const localCaptainId = getItemFromLocalStorage('captain_id');

    if (sessionCaptainId || localCaptainId) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return null;
};

export default Default;
