import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import EditBanner from './EditBanner';
import Loading from '../components/Loading';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SubscriptionCheck from '../components/SubscriptionCheck';

function Banners() {

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');

    const [menuList, setMenuList] = useState([]);
    const [displayPositionList, setDisplayPositionList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [search, setSearch] = useState('');
    const [image, setImage] = useState('');
    const [displayPosition, setDisplayPosition] = useState([]);
    const [category, setCategory] = useState([]);
    const [title, setTitle] = useState('');
    const [buttonLink, setButtonLink] = useState('');
    const [status, setStatus] = useState(0);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isImageValid, setIsImageValid] = useState(true);
    const [itemId, setItemId] = useState('');
    const [itemName, setItemName] = useState('');
    const [isDeleteModal, setIsDeleteModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [isAddPopup, setIsAddPopup] = useState(false);

    const errorRef = useRef(null);

    useEffect(() => {
        if (error && errorRef.current) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error]);

    const fetchMenuList = useCallback(async () => {
        if (businessListingId && token) {
            setLoading(true);
            const formData = new FormData();
            formData.append('listing_id', businessListingId);
            formData.append('token', token);

            if (search) {
                formData.append('keyword', search);
            }

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.bannerList}`,
                    data: formData
                });
                if (response.data.items && Array.isArray(response.data.items)) {
                    setMenuList(response.data.items);
                } else {
                    console.log('Table list not found.');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        }
    }, [businessListingId, token, search]);

    useEffect(() => {
        fetchMenuList();
    }, [fetchMenuList]);

    useEffect(() => {
        const fetchBaseData = async () => {
            if (isAddPopup && businessListingId && token) {
                const formData = new FormData();
                formData.append('listing_id', businessListingId);
                formData.append('token', token);

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.bannerBaseData}`,
                        data: formData
                    });
                    if (response.data.status === 'ok') {
                        setDisplayPositionList(response.data.banner_slides);
                        setCategoryList(response.data.banner_category);
                    } else {
                        console.log('Base data not found.');
                    }
                } catch (error) {
                    setError(error.message);
                }
            }
        };

        fetchBaseData();
    }, [isAddPopup, businessListingId, token]);


    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.src = objectUrl;

            img.onload = () => {
                if (img.width === 800 && img.height === 320) {
                    setImage(file);
                    setIsImageValid(true);
                } else {
                    toast.error('Image dimensions should be 800px x 320px.');
                    setImage('');
                    setIsImageValid(false);
                }
                URL.revokeObjectURL(objectUrl);
            };
        }
    };

    const handleDisplayPositionChange = (e) => {
        const { value, checked } = e.target;
        setDisplayPosition((prev) =>
          checked ? [...prev, value] : prev.filter((id) => id !== value)
        );
    };

    const handleCategoryChange = (e) => {
        const value = parseInt(e.target.value);
        setCategory((prevSelected) => {
            if (prevSelected.includes(value)) {
                return prevSelected.filter((id) => id !== value);
            } else {
                return [...prevSelected, value];
            }
        });
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.checked ? 1 : 2);
    };

    const openEditModal = async (id) => {
        setItemId(id);
        setIsEditModal(true);
    }

    const handleEditModal = (e) => {
        setIsEditModal(false);
    }

    const handleAddData = async () => {

        if (!isImageValid) {
            toast.error('Image dimensions should be 800px x 320px.');
            return;
        }

        const banner_category = category.join(',');

        let formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('entry_pic', image);
        formData.append('banner_name', title);
        formData.append('banner_category', banner_category);
        formData.append('banner_destination', buttonLink);
        formData.append('banner_status', status);
        displayPosition.forEach((position, index) => {
            formData.append(`banner_slide_${index + 1}`, 1);
        });

        setLoader(true);
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.bannerSaveEntry}`,
                data: formData
            });

            if (response.data.status === 'completed') {
                Swal.fire({
                    icon: 'success',
                    text: 'banner added successfully!',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/listing/banners';
                    }
                });
            } else if (response.data.status === 'error') {
                response.data.list.map((error) => (
                    toast.error(error)
                ));
            } else {
                setError('Error in saving data.');
            }
        } catch (err) {
            setError('An error occurred while saving data.');
        } finally {
            setLoader(false);
        }
    }
    

    const openDeleteModal = async (id, name) => {
        setItemId(id);
        setItemName(name);
        setIsDeleteModal(true);
    };

    const handleItemDelete = async () => {
        if (businessListingId && token) {
          const cData = new FormData();
          cData.append('token', token);
          cData.append('listing_id', businessListingId);
          cData.append('banner_id', itemId);
    
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              url: `${params.baseURL}${params.endpoints.bannerDelete}`,
              data: cData
            });
            if (response.data.status === 'completed') {
                setIsDeleteModal(false);
                toast.success('Banner deleted successfully.');
                fetchMenuList();
            }
            else if(response.data.status === 'error') {
              setError(response.data.list);
            }
            else {
              setError('Error Deleting Data.');
            }
          } catch (error) {
            setError(error.message);
          }
        }
    };

    const changeStatus = async (menuItemId, newStatus) => {
        if (businessListingId && token) {
            const cData = new FormData();
            cData.append('token', token);
            cData.append('listing_id', businessListingId);
            cData.append('banner_id', menuItemId);
            cData.append('onoff', newStatus);
      
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.bannerStatusUpdate}`,
                data: cData
              });
              if (response.data.status === 'completed') {
                //toast.success('Status changed successfully.');
                setMenuList(prevList =>
                    prevList.map(item =>
                        item.banner_id === menuItemId ? { ...item, banner_status: newStatus } : item
                    )
                );
              }
              else if(response.data.status === 'error') {
                setError(response.data.list);
              }
              else {
                setError('Error changing status.');
              }
            } catch (error) {
              setError(error.message);
            }
        }
    }

    return (
        <div className="manage-section">
            {loader && <Loader />}
            {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 

            <div className='lsertbox'>
                <h4>Banners</h4>
            </div>
            <div className='search-box'>
                <input type="text" className="form-control" placeholder="Search" value={search} onChange={handleSearchChange} />
            </div>

            {loading ? (
                <Loading />
            ) : (
                <div className="menu-item-list">
                    <div>
                        {menuList.length > 0 ? (<>
                            {menuList.map((item) => (
                                <div className='ml-box lser-box' key={item.banner_id}>
                                    <div className='ml-header lser-header'>
                                        <div className='lser-ih'>
                                            <div>
                                                <img src={item.banner_image_url ? item.banner_image_url : '/assets/img/icons/default_user.svg'} className='lser-img' alt='' />
                                            </div>
                                            <div>
                                                <h4 className='lser-name mb-0'>{item.banner_name}</h4>
                                                <div style={{margin:'10px 0'}}>
                                                {item.slide_list.map((slide) => (
                                                    <span key={slide.id} className='slide-list'>
                                                        {slide.title}
                                                    </span>
                                                ))}
                                                </div>
                                                {item.category_list.map((slide) => (
                                                    <span key={slide.id} className='ban-cat'>
                                                        {slide.title}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='ml-body lser-body'>
                                        <div className='lserv-btn-box'>
                                            <button className="ml-btn fs-success" onClick={() => openEditModal(item.banner_id)} >
                                                Edit
                                            </button>
                                            <button className="ml-btn fs-danger" onClick={() => openDeleteModal(item.banner_id, item.banner_name)} >
                                                Delete
                                            </button>
                                        </div>
                                        <div class="toggle">                                                                                   
                                            <input 
                                                type="checkbox" 
                                                name="status" 
                                                id={`tstatus_${item.banner_id}`}
                                                checked={item.banner_status === 1}
                                                onChange={() => changeStatus(item.banner_id, item.banner_status === 1 ? 2 : 1)}
                                            /> 
                                            <label htmlFor={`tstatus_${item.banner_id}`} style={{cursor:'pointer'}}></label>  
                                        </div>
                                    </div>
                                    <div className='ml-footer'>
                                        <p>Created : {item.banner_created_time}</p>
                                        <p>Modified : {item.banner_last_modified_time ? item.banner_last_modified_time : '---'}</p>
                                    </div>
                                </div>
                            ))}
                        </>) : (<>
                            <div className='empty-section'>
                                <img src={'/assets/img/no_listing_gallery.svg'} alt='Fyre Search' />
                                <p>No Table List Found</p>
                            </div>
                        </>)}
                    </div>
                </div>
            )}

            {isAddPopup && (
                <div className='popup'>
                    <div className='popup-content mb-5'>
                    <SubscriptionCheck>
                    {loading && <p>Loading...</p>}
                    {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
                        <div className='cpo-header' style={{ margin: '0' }}>
                            <div>
                                <h4>Add New Banner</h4>
                            </div>
                            <span className='popup-close' onClick={() => setIsAddPopup(false)}>&times;</span>
                        </div>
                        <div className='cposb'>
                            <form className="form-section needs-validation" encType="multipart/form-data">
                                <div className='row'>
                                    <div className='col-12 mb-2'>
                                    <label htmlFor="service_img" className="logo-title" style={{ cursor: 'pointer' }}>
                                        {image ? (
                                            <img src={URL.createObjectURL(image)} alt="Profile" className="upload_img" />
                                        ) : (
                                            <img src={'/assets/img/background/menu_banner.png'} alt="Profile" className="upload_img" />
                                        )}
                                        <input 
                                            type="file" 
                                            name="service_img" 
                                            id="service_img" 
                                            className="upload d-none" 
                                            onChange={handleImageUpload} 
                                        />
                                        <span className='image__upload_btn'>select image</span>
                                    </label>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <legend class="col-form-label">Display Position</legend>
                                        <div className='radio-box'>
                                            {displayPositionList.map((type) => (
                                                <div className="form-check lserv-form-check" key={type.id}>
                                                    <label className="lserv-radio-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                        <input 
                                                            name='category'
                                                            className="lserv-radio-input" 
                                                            type="checkbox" 
                                                            id={type.title.toLowerCase().replace(' ', '')} 
                                                            value={type.id}
                                                            onChange={handleDisplayPositionChange}
                                                        />
                                                        <span className='lserv-radio-span'><em><i class="fas fa-check"></i></em>{type.title}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-md-12 mb-3'>
                                        <legend class="col-form-label">Category</legend>
                                        <div className='radio-box' style={{display:'inline'}}>
                                            {categoryList.map((type) => (
                                                <div className="form-check lserv-form-check" style={{display:'inline-block',marginBottom:'10px'}} key={type.id}>
                                                    <label className="lserv-radio-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                        <input 
                                                            name='category'
                                                            className="lserv-radio-input" 
                                                            type="checkbox" 
                                                            id={type.title.toLowerCase().replace(' ', '')} 
                                                            value={type.id}
                                                            onChange={handleCategoryChange}
                                                        />
                                                        <span className='lserv-radio-span'><em><i class="fas fa-check"></i></em>{type.title}</span>
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <input type="text" id="title" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                            <label htmlFor="title">Title</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="form-floating">
                                            <input type="text" id="buttonLink" className="form-control" value={buttonLink} onChange={(e) => setButtonLink(e.target.value)} />
                                            <label htmlFor="buttonLink">Button Link</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className='status__box'>
                                            <div>
                                                <label>Status</label>
                                            </div>
                                            <div class="toggle">                                                                                   
                                                <input 
                                                    type="checkbox" 
                                                    name="addStatus" 
                                                    id="addStatus" 
                                                    checked={status === 1}
                                                    onChange={handleStatusChange}
                                                /> 
                                                <label for="addStatus" style={{cursor:'pointer'}}></label>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-md-12 text-end'>
                                        <button type="button" className="cp-btn" onClick={handleAddData}>SAVE</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </SubscriptionCheck>
                    </div>
                </div>
            )}

            {isDeleteModal && itemId &&(
                <div className='modal-overlay'>
                <div className='modal-content'>
                    <div className='modal-body'>
                    <h6>Delete</h6>
                    <p>Would you like to delete banner title {itemName} ?</p>
                    </div>
                    <div className='modal-footer'>
                        <button className="close-button" onClick={() => setIsDeleteModal(false)} style={{marginRight:'4rem'}}>No</button>
                        <button className="close-button" onClick={handleItemDelete}>Yes</button>
                    </div>
                </div>
                </div>
            )}

            {isEditModal && (
                <EditBanner
                    itemId={itemId}
                    onClose={handleEditModal}
                />
            )}

            <button className='add-data' onClick={() => setIsAddPopup(true)}><i className="fas fa-plus"></i></button>
        </div>
    );
}

export default Banners;
