import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Drawer from '@mui/material/Drawer';

const Sidebar = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [postType, setPostType] = useState(null);
  const [hasVideoAccess, setHasVideoAccess] = useState(false);
  const navigate = useNavigate();

  const captainData = getItemFromLocalStorage('captainProfile');
  const token = captainData ? captainData.captain_access_token : null;
  const listing_id = getItemFromLocalStorage('listing_id');

  useEffect(() => {
    const getCaptainProfile = async () => {
      if (token) {
        const formData = new FormData();
        formData.append('token', token);

        try {
          const response = await axios.post(
            `${params.baseURL}${params.endpoints.getProfile}`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          if (response.data.status === 'found') {
            const profileData = response.data.profile_data;
            setHasVideoAccess(profileData.has_video_access === 1);
            setPostType(profileData.has_video_access === 1 ? null : 1);
          } else {
            console.log('Profile not found');
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    };

    getCaptainProfile();
  }, [token]);

  const handleNavClick = (e) => {
    e.preventDefault();
    if (hasVideoAccess) {
      setDrawerOpen(true);
    } else {
      navigate(`/listing/add-post?postType=1`);
    }
  };

  const handlePostTypeChange = (event) => {
    const selectedPostType = Number(event.target.value);
    setPostType(selectedPostType);
    setDrawerOpen(false);
    navigate(`/listing/add-post?postType=${selectedPostType}`);
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <NavLink to="/home" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_dashboard_active.svg' : '/assets/img/icons/fs_dashboard.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Dashboard</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/my-profile" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_myprofile_active.svg' : '/assets/img/icons/fs_myprofile.svg'}
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>My Profile</span>
            </>
          )}
          </NavLink>
        </li>
        <span className='side-line'></span>
        <p>List Your Business</p>
        <li className="nav-item">
          <NavLink to="/add-listing" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_add_buss_active.svg' : '/assets/img/icons/fs_add_buss.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Add Your Business</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/manage-listing" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_manage_active.svg' : '/assets/img/icons/fs_manage.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Manage</span>
            </>
          )}
          </NavLink>
        </li>
        
        {listing_id && (
        <>
        <span className='side-line'></span>
        <p>Manage</p>
        <li className="nav-item">
          <NavLink to="javascript:void(0)" className="nav-link" onClick={handleNavClick}>
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_post_active.svg' : '/assets/img/icons/fs_post.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Post</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/banners" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_banner_active.svg' : '/assets/img/icons/fs_banner.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Banner</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/menu" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_side_menu_active.svg' : '/assets/img/icons/fs_side_menu.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Menu</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/tables" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_side_table_active.svg' : '/assets/img/icons/fs_side_table.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Tables</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/offers" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_side_offer_active.svg' : '/assets/img/icons/fs_side_offer.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Offers</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/push-notifications" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_push_active.svg' : '/assets/img/icons/fs_push.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Push notification</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/tagged" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_tagged_active.svg' : '/assets/img/icons/fs_tagged.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Tagged</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/listing/ratings" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_rating_active.svg' : '/assets/img/icons/fs_rating.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Ratings</span>
            </>
          )}
          </NavLink>
        </li>
        </>
        )}
        
        <span className='side-line'></span>
        <p>Others</p>
        <li className="nav-item">
          <NavLink to="/plans" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_side_subs.svg' : '/assets/img/icons/fs_side_subs.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Subscribe Now!</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/my-subscription" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_side_subs.svg' : '/assets/img/icons/fs_side_subs.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>My Subscription</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="https://fyresearch.com/about" target='_blank' className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_about_active.svg' : '/assets/img/icons/fs_about.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>About Fyre Search</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="https://fyresearch.com/terms" target='_blank' className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_term_active.svg' : '/assets/img/icons/fs_terms.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Terms & Conditions</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="https://fyresearch.com/privacy-policy" target='_blank' className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_privacy_active.svg' : '/assets/img/icons/fs_privacy.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Privacy Policy</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="https://fyresearch.com/faqs" target='_blank' className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_faqs_active.svg' : '/assets/img/icons/fs_faqs.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>FAQs</span>
            </>
          )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="javascript:void(0)" className="nav-link">
          {({ isActive }) => (
            <>
              <img 
                src={isActive ? '/assets/img/icons/fs_bigdata.svg' : '/assets/img/icons/fs_bigdata.svg'} 
                className='side-icon' 
                alt='Fyre Search Icons' 
              />
              <span>Big Data</span>
            </>
          )}
          </NavLink>
        </li>
      </ul>

      <Drawer
          anchor='bottom'
          className='drawer'
          open={isDrawerOpen}
          onClose={() => setDrawerOpen(false)}
      >
          <div className='drawer-content post-popup'>
              <div className='post-popup-box' style={{padding: '50px 10px'}}>
                <div>
                  <span className='drawer-close-btn' onClick={() => setDrawerOpen(false)}>
                    <img src={'/assets/img/icons/close_icon.svg'} className='w-100' alt='' />
                  </span>
                </div>
                  <div className='popup-title' style={{ textAlign: 'center', marginBottom: '1rem', fontWeight: '600' }}>CHOOSE AN OPTION</div>
                  <div className='popup-options'>
                      <div className="radio-inputs">
                          <label>
                              <input className="radio-input post"
                                  type="radio"
                                  name="engine"
                                  value={1}
                                  checked={postType === 1}
                                  onChange={handlePostTypeChange}
                              />
                              <span className="radio-tile post">
                                  <span className="radio-icon">
                                      <img src={'/assets/img/icons/post_image.svg'} alt='' className='post-img-icon' />
                                  </span>
                                  <span className='radio-post-title'>Photo</span>
                              </span>
                          </label>

                          <label>
                              <input className="radio-input post"
                                  type="radio"
                                  name="engine"
                                  value={2}
                                  checked={postType === 2}
                                  onChange={handlePostTypeChange}
                              />
                              <span className="radio-tile post">
                                  <span className="radio-icon">
                                      <img src={'/assets/img/icons/post_video.svg'} alt='' className='post-img-icon' />
                                  </span>
                                  <span className='radio-post-title'>Video</span>
                              </span>
                          </label>
                      </div>
                  </div>
              </div>
          </div>
      </Drawer>

    </aside>
  );
};

export default Sidebar;
