function ImageLoading() {
    return (
        <div className="row loading-img">
            <div className="col-md-4">
                <div className="shine-box">
                    <box className="shine"></box>
                    <div>
                        <lines className="shine"></lines>
                        <lines className="shine"></lines>
                        <lines className="shine"></lines>
                    </div>
                </div>
                <photo className="shine"></photo>
            </div>
            <div className="col-md-4">
                <div className="shine-box">
                    <box className="shine"></box>
                    <div>
                        <lines className="shine"></lines>
                        <lines className="shine"></lines>
                        <lines className="shine"></lines>
                    </div>
                </div>
                <photo className="shine"></photo>
            </div>
            <div className="col-md-4">
                <div className="shine-box">
                    <box className="shine"></box>
                    <div>
                        <lines className="shine"></lines>
                        <lines className="shine"></lines>
                        <lines className="shine"></lines>
                    </div>
                </div>
                <photo className="shine"></photo>
            </div>
        </div>
    )
}

export default ImageLoading;