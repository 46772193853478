import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import params from '../config/Params';
import SearchOutletPopup from '../components/SearchOutletPopup';
import ImageCropper from '../components/ImageCropper';
import { getItemFromLocalStorage, setWithExpiry } from '../utils/storageUtils';
import GoogleMapComponent from '../components/GoogleMapComponent';
import { toast } from 'react-toastify';

function MyProfile() {
  const navigate = useNavigate();

  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [imagePreview, setImagePreview] = useState('/assets/img/default_logo.svg');
  const [name, setName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [username, setUsername] = useState('');
  const [gstin, setGstin] = useState('');
  const [about, setAbout] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [wnumber, setWnumber] = useState('');
  const [captain, setCaptain] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [captainId, setCaptainId] = useState('');
  const [sourceId, setSourceId] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const [showPopup, setShowPopup] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [locationId, setLocationId] = useState('');
  const [selectedOutlet, setSelectedOutlet] = useState(null);

  const [showMap, setShowMap] = useState(false);

  const errorRef = useRef(null);
  const successMessageRef = useRef(null);

  useEffect(() => {
    if ((error && errorRef.current) || (successMessage && successMessageRef.current)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error, successMessage]);

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const handleCloseMap = () => {
    setShowMap(false);
  }

  useEffect(() => {
    const profileData = getItemFromLocalStorage('captainProfile');
    if (profileData) {
      setName(profileData.business_name || '');
      setCompanyName(profileData.business_legal_name || '');
      setUsername(profileData.captain_username || '');
      setGstin(profileData.business_gst_no || '');
      setAbout(profileData.business_info || '');
      setAddress(profileData.business_street || '');
      setEmail(profileData.business_contact_email || '');
      setWnumber(profileData.business_contact_number || '');
      setCaptain(profileData.captain_name || '');
      setLatitude(profileData.business_latitude || null);
      setLongitude(profileData.business_longitude || null);
      setLocationId(profileData.related_location_id || '');
      setSelectedOutlet(profileData.business_name || null);
      setCaptainId(profileData.captain_id || '');
      setSourceId(profileData.business_login_id || '');
      setImagePreview(profileData.captain_logo || 'assets/img/default.png');
    }
  }, []);

  const handleCrop = (croppedDataUrl) => {
    setCroppedImage(croppedDataUrl);
    setShowCropper(false);
  };

  const base64ToBlob = (base64, mime) => {
    const byteString = atob(base64.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mime });
  };

  const handleSearchOutlet = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setShowPopup(true);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClaimLocation = async (outlet) => {
    setSelectedOutlet(outlet);
    setName(outlet.title);
    setAddress(outlet.description);
    setLocationId(outlet.id);

    let fpData = new FormData();
    fpData.append('country', 'in');
    fpData.append('title', outlet.title);
    fpData.append('description', outlet.description);
    fpData.append('place_id', outlet.id);
    fpData.append('utype', 'captain');

    try {
      const fpResponse = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.findLatLng}`,
        data: fpData
      });

      if (fpResponse.data.status === 'done') {
        console.log(fpResponse.data.lat);
        console.log(fpResponse.data.lng);
        setLatitude(fpResponse.data.lat);
        setLongitude(fpResponse.data.lng);
      } else if (fpResponse.data.status === 'error') {
        console.error('Error response:', fpResponse.data.list);
      } else {
        console.error('Error in saving data.');
      }
    } catch (err) {
      console.error('An error occurred while saving data:', err);
    }

    setShowPopup(false);
  };

  const handleNotOnList = (outletName) => {
    setName(outletName);
    setShowPopup(false);
  }

  const validateForm = () => {
    const errors = {};
    if (!address) errors.address = true;
    if (!email) errors.email = true;
    if (!wnumber) errors.wnumber = true;
    if (!captain) errors.captain = true;
    if (!username) errors.username = true;
    return errors;
  }

  const handleGstinChange = async (e) => {
    const value = e.target.value;
    setGstin(value);

    if (value.length === 15) {
      let gstData = new FormData();
      gstData.append('gstin', value);
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          url: `${params.baseURL}${params.endpoints.verifyGst}`,
          data: gstData
        });
  
        if (response.data.status === 'ok') {
          setCompanyName(response.data.gst_legal_name);
        } else if (response.data.status === 'error') {
          toast.error(response.data.list);
        } else {
          setError('An error occurred while saving data.');
        }
      } catch (err) {
        setError('An error occurred while saving data.');
      }
    }
  };

  const ProceedRegister = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
   
    let formData = new FormData();
    if (croppedImage) {
      const blob = base64ToBlob(croppedImage, 'image/jpg');
      formData.append('captain_logo', blob);
    }
    formData.append('business_gst_no', gstin);
    formData.append('business_legal_name', companyName);
    formData.append('id', captainId);
    formData.append('source', 'OTP');
    formData.append('source_id', sourceId);
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', wnumber);
    formData.append('cusername', username);
    formData.append('captain', captain);
    formData.append('address', address);
    formData.append('bio', about);
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('related_location_id', locationId);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        url: `${params.baseURL}${params.endpoints.updateProfile}`,
        data: formData
      });

      if (response.data.status === 'updated') {
        setWithExpiry('captainProfile', JSON.stringify(response.data.profile_data));
        setSuccessMessage('Your Profile Updated Successfully.');
        navigate('/my-profile');
      } else if (response.data.status === 'error') {
        toast.error(response.data.list);
      } else {
        setError('Error in saving data.');
      }
    } catch (err) {
      setError('An error occurred while saving data.');
    }
  }

  return (
    <section className="registration-form" style={{ height: '100%', overflow: 'hidden' }}>
      <div className="form-body">
        <div className="row">
          <div className="form-holder">
            <div className="form-content myp-form">
              <div className="form-items">
                {/*<h4 className='register-title text-uppercase' style={{color:'#000'}}>Update Profile</h4>*/}
                {successMessage && <div className="alert alert-success mb-4" ref={successMessageRef}>{successMessage}</div>}
                {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
                <form className="needs-validation" encType="multipart/form-data" onSubmit={ProceedRegister}>

                <div className="os-section">
                    <div className="os-box">
                    <button type='button' className='search-outlet-btn' 
                      onClick={handleSearchOutlet}>
                      {selectedOutlet && selectedOutlet.title ? selectedOutlet.title : name || 'Search Outlet'}
                    </button>
                    {showPopup && (
                      <SearchOutletPopup
                        latitude={latitude}
                        longitude={longitude}
                        onClose={handleClosePopup}
                        onClaimLocation={handleClaimLocation}
                        onNotOnList={handleNotOnList}
                      />
                    )}
                    </div>
                    <div className="logo-box">
                      <label htmlFor="captainLogo" className="logo-title" onClick={() => setShowCropper(true)}>
                        <img src={croppedImage || imagePreview} alt="Profile" className="rounded-circle" />
                        <span>Upload Profile Logo</span>
                      </label>
                      {showCropper && (
                        <ImageCropper onCrop={handleCrop} onClose={() => setShowCropper(false)} />
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-floating">
                      <input className="form-control" type="text" maxlength="15" id="gstin" name="gstin" value={gstin} onChange={handleGstinChange} />
                      <label htmlFor="gstin">GSTIN (If Applicable)</label>
                    </div>
                    <span className="form-hint">Optional</span>
                  </div>

                  <div className="col-md-12">
                    <div className="form-floating">
                      <input className="form-control" type="text" id="companyName" name="companyName" value={companyName} onChange={e => setCompanyName(e.target.value)} />
                      <label htmlFor="companyName">Company Name</label>
                    </div>
                    <span className="form-hint">Optional, If GSTIN Applicable</span>
                  </div>

                  <div className="col-md-12">
                    <div className="form-floating">
                      <input className="form-control" type="text" id="about" name="about" value={about} onChange={e => setAbout(e.target.value)} />
                      <label htmlFor="about">About</label>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-floating">
                      <input style={{paddingRight:'40px'}} className={`form-control ${formErrors.address ? 'is-invalid' : ''}`} type="text" id="address" name="address" value={address} onChange={e => setAddress(e.target.value)} />
                      <label htmlFor="address">Address</label>
                      <button type='button' className='location-icon' onClick={handleLocationClick}>
                        <img src={'/assets/img/icons/location_marker.svg'} alt='' />
                      </button> 
                    </div>
                    <span className="form-hint">Required</span>
                  </div>

                  <div className='row'>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input className={`form-control ${formErrors.email ? 'is-invalid' : ''}`} type="email" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                        <label htmlFor="email">Email ID</label>
                      </div>
                      <span className="form-hint">Required</span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating">
                        <input className={`form-control ${formErrors.wnumber ? 'is-invalid' : ''}`} type="text" id="whatsappNumber" name="whatsappNumber" value={wnumber} onChange={e => setWnumber(e.target.value)} />
                        <label htmlFor="whatsappNumber">Whatsapp Number</label>
                      </div>
                      <span className="form-hint">Required</span>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input className={`form-control ${formErrors.captain ? 'is-invalid' : ''}`} type="text" id="captain" name="captain" value={captain} onChange={e => setCaptain(e.target.value)} />
                        <label htmlFor="captain">Captain</label>
                      </div>
                      <span className="form-hint">Required</span>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating">
                        <input className={`form-control ${formErrors.username ? 'is-invalid' : ''}`} type="text" id="username" name="username" value={username} onChange={e => setUsername(e.target.value)} />
                        <label htmlFor="username">Username</label>
                      </div>
                      <span className="form-hint">Required</span>
                    </div>
                  </div>

                  <div className="form-check tc-box mt-3">
                    <input className="form-check-input" type="checkbox" value="" id="invalidCheck" checked required />
                    <label className="form-check-label">I accept <a href={'https://fyresearch.com/terms'} target='_blank' rel="noreferrer">Terms & Condition</a> and <a href={'https://fyresearch.com/privacy-policy'} target='_blank' rel="noreferrer">Privacy Policy</a></label>
                  </div>

                  <div className="form-button mt-3">
                    <button id="submit" type="submit" className="cp-btn">Update</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showMap && (
        <div>
          <GoogleMapComponent 
            setAddress={setAddress} 
            setLatitude={setLatitude} 
            setLongitude={setLongitude}
            onClose={handleCloseMap}
          />
        </div>
      )}

    </section>
  );
}

export default MyProfile;
