import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import params from '../config/Params';
import { getItemFromLocalStorage, setWithExpiry } from '../utils/storageUtils';
import '../assets/css/login.css';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(120);
  const [showTimer, setShowTimer] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const localCaptainId = getItemFromLocalStorage('captain_id');

    if (localCaptainId) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    let timer;
    if (otpSent && showTimer) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            setShowTimer(false);
            setResendDisabled(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [otpSent, showTimer]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      setVerificationInProgress(true);
      let item = { generated_for: 'captain', generated_for_mobile_cc: '91', generated_for_mobile: mobileNumber };
      const response = await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: `${params.baseURL}${params.endpoints.createOtp}`,
        data: item
      });
      if (response.data.status === 'ok') {
        setSuccessMessage('OTP sent successfully!');
        setOtpSent(true);
        setResendDisabled(true);
        setShowTimer(true);
        setCountdown(120);
      } else if (response.data.status === 'error') {
        setErrorMessage('Please enter valid mobile number');
      } else {
        console.log(response);
        setErrorMessage('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setErrorMessage('Failed to send OTP. Please try again later.');
    } finally {
      setVerificationInProgress(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    await handleSendOtp(e);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let otpItem = { generated_otp: otp, generated_for: 'captain', generated_for_mobile_cc: '91', generated_for_mobile: mobileNumber };
      const response = await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: `${params.baseURL}${params.endpoints.verifyOtp}`,
        data: otpItem
      });
      if (response.data.status === 'ok') {
        const fullMobileNumber = '91' + mobileNumber;
        let userItem = { source: 'OTP', source_id: fullMobileNumber };
        const userResponse = await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: `${params.baseURL}${params.endpoints.isUserExist}`,
          data: userItem
        });

        if (userResponse.data.status === 'exists') {
          setSuccessMessage('Login successful!');
          setWithExpiry('captainProfile', JSON.stringify(userResponse.data.profile_data));
          setWithExpiry('captain_id', userResponse.data.registered_profile_id);
          navigate('/home');
        } else {
          navigate('/register', { state: { mobileNumber } });
        }
      } else {
        setErrorMessage('Login failed. Please check your OTP and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Login failed. Please try again later.');
    }
  };

  return ( 
  <>
    <Helmet>
      <title>Login</title>
      <meta name="description" content="Claim your pre-existing Location Gallery at FS curated specifically for PBCL (Pubs, Bars, Cafes, Lounges) & Restaurant Industry to leverage The Most Organized Virtual Marketplace - Wire." />
    </Helmet>
    <section className='login-page'>
      <div className="box">
        <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-4 col-4">
            <div id="marquee1" className="image-box">
              <div style={{ position: 'absolute' }}>
                <img src={'https://wire.statustag.com/images/Image1/1.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/1.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/2.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image3/1.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/3.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image3/2.webp'} className="img-fluid" alt="fyre search login" /><br /><br />
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-4 col-4">
            <div id="marquee2" className="image-box">
              <div style={{ position: 'absolute' }}>
                <img src={'https://wire.statustag.com/images/Image2/4.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/5.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/6.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image1/2.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/7.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/8.webp'} className="img-fluid" alt="fyre search login" /><br /><br />
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-4 col-4">
            <div id="marquee3" className="image-box">
              <div style={{ position: 'absolute' }}>
                <img src={'https://wire.statustag.com/images/Image3/3.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image3/4.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/9.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image3/5.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/10.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image1/3.webp'} className="img-fluid" alt="fyre search login" /><br /><br />
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-4 col-4">
            <div id="marquee4" className="image-box">
              <div style={{ position: 'absolute' }}>
                <img src={'https://wire.statustag.com/images/Image2/11.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/12.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/13.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image1/4.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/14.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/15.webp'} className="img-fluid" alt="fyre search login" /><br /><br />
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-3 col-sm-4 col-4">
            <div id="marquee5" className="image-box">
              <div style={{ position: 'absolute' }}>
                <img src={'https://wire.statustag.com/images/Image1/5.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/16.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/17.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image3/6.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image2/18.webp'} className="img-fluid" alt="fyre search login" /><br />
                <img src={'https://wire.statustag.com/images/Image3/7.webp'} className="img-fluid" alt="fyre search login" /><br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='login-overlay'></div>

      <div className="loginBox">
        <img className="user" src={'https://fyresearch.com/assets/img/features/Multiple-Business-Listing.svg'} alt='fyre search logo' />
        <div className='loginFormBox'>
          <div>
            {!otpSent && (
              <>
                <form id="mobileForm" autoComplete="off">
                  <div className="login-form">
                    <label className='login-title'>SIGN UP / LOGIN WITH YOUR MOBILE NUMBER</label>
                    <div className="input-box d-flex" style={{ height: '40px' }}>
                      <input value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} disabled={verificationInProgress} className="form-control" type="number" name="number" placeholder="Enter Your Number" />
                    </div>

                    <div className="button mt-4">
                      <button className="btn btn-primary btn-block login-btn" onClick={handleSendOtp} disabled={verificationInProgress}>
                        {verificationInProgress ? 'Sending OTP...' : 'Continue'}
                      </button>
                    </div>

                    <div className="text-center">
                      <span className="d-block terms-block">BY CLICKING ON CONTINUE YOU AGREE TO OUR&nbsp;
                        <a href={'https://fyresearch.com/terms'} target='_blank' rel="noreferrer">TERMS</a>&nbsp; & &nbsp; 
                        <a href={'https://fyresearch.com/privacy-policy'} target='_blank' rel="noreferrer">PRIVACY POLICY</a>
                      </span>
                    </div>
                  </div>
                </form>
              </>
            )}

            {successMessage && <div className='message-box' style={{ background: 'green', marginBottom: '1rem' }}>{successMessage}</div>}
            {errorMessage && <div className='message-box' style={{ background: 'red', marginTop: '1rem' }}>{errorMessage}</div>}

            {otpSent && (
              <>
                <form id="otpForm" autoComplete="off">
                  <div className="login-form">
                    <label htmlFor="mobile" style={{ textAlign: 'center' }}>PLEASE ENTER VERIFICATION CODE</label>
                    <input value={otp} onChange={(e) => setOtp(e.target.value)} disabled={verificationInProgress} className="form-control" type="text" />
                    <div className="text-center mb-4">
                      {showTimer ? (             
                        <div className="countdown resend-text">Expire in {countdown > 0 ? `${countdown} seconds` : ''}</div>
                      ) : (
                        <div className="button">
                          <span className="d-block resend-text">DIDN'T RECEIVE THE CODE ?</span>
                          <button id='resendOtp' className="btn btn-primary btn-block login-btn" onClick={handleResendOtp} disabled={resendDisabled}>
                            RESEND
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="button">
                      <button className="btn btn-primary btn-block login-btn" onClick={handleLogin} disabled={verificationInProgress}>
                        {verificationInProgress ? 'Verifying...' : 'VERIFY'}
                      </button>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  </>
  );
};

export default Login;
