//import logo from './logo.svg';
import './App.css';
import './assets/css/style.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlankLayout from './layouts/BlankLayout';
import MainLayout from './layouts/MainLayout';
import ProtectedRoute from './ProtectedRoute';
import Default from './pages/Default';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import AddListing from './pages/AddListing';
import Logout from './pages/Logout';
import Plans from './pages/Plans';
import MyProfile from './pages/MyProfile';
import MySubscription from './pages/MySubscription';
import ManageListing from './pages/ManageListing';
import EditListing from './pages/EditListing';
import Notifications from './pages/Notifications';
import Tagged from './listing/Tagged';
import Ratings from './listing/Ratings';
import Click from './listing/Click';
import AddPost from './listing/AddPost';
import EditPost from './listing/EditPost';
import Menu from './listing/Menu';
import Tables from './listing/Tables';
import Offers from './listing/Offers';
import AttachOffer from './listing/AttachOffer';
import PushNotifications from './listing/PushNotifications';
import { SubscriptionProvider } from './SubscriptionContext';
import Banners from './listing/Banners';
import LoginNew from './pages/LoginNew';

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const smallScreen = window.innerWidth < 1200;
      setIsSmallScreen(smallScreen);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isSmallScreen) {
    return (
      <div className="small-screen-message">
        <a href="https://play.google.com/store/apps/details?id=com.fyresearch.partner">
          <img src={'/assets/img/fs_mobile_view.png'} alt="fyre search" className="img-fluid w-100" />
        </a>
      </div>
    );
  }

  return (
    <Router>
       <Routes>
         <Route
          path="/"
          element={
            <ProtectedRoute>
              <BlankLayout>
                <Default />
              </BlankLayout>
            </ProtectedRoute>
          }
         />
         <Route 
          path="/login" 
          element={
            <BlankLayout>
              <Login />
            </BlankLayout>
          } 
         />
         <Route 
          path="/login-new" 
          element={
            <BlankLayout>
              <LoginNew />
            </BlankLayout>
          } 
         />
         <Route 
          path="/register" 
          element={
            <BlankLayout>
              <Register />
            </BlankLayout>
          } 
         />
         <Route
          path="/home"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Home />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyProfile />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/plans"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Plans />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/my-subscription"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MySubscription />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/add-listing"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="captain" moduleFeature='listing'>
                <MainLayout>
                  <AddListing />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/manage-listing"
          element={
            <ProtectedRoute>
              <MainLayout>
              <ManageListing />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/edit-listing/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
              <EditListing />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/add-post"
          element={
            <ProtectedRoute>
              <MainLayout>
              <AddPost />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/edit-post/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
              <EditPost />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/banners"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="listing" moduleFeature="banner">
                <MainLayout>
                  <Banners />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/menu"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="listing" moduleFeature="menu">
                <MainLayout>
                  <Menu />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/tables"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="listing" moduleFeature="table">
                <MainLayout>
                  <Tables />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/offers"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="listing" moduleFeature="offer">
                <MainLayout>
                  <Offers />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/attach-offer"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="listing" moduleFeature="offer">
                <MainLayout>
                  <AttachOffer />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/push-notifications"
          element={
            <ProtectedRoute>
              <SubscriptionProvider type="listing" moduleFeature="push">
                <MainLayout>
                  <PushNotifications />
                </MainLayout>
              </SubscriptionProvider>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/tagged"
          element={
            <ProtectedRoute>
              <MainLayout>
              <Tagged />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/ratings"
          element={
            <ProtectedRoute>
              <MainLayout>
              <Ratings />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/listing/click"
          element={
            <ProtectedRoute>
              <MainLayout>
              <Click />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <MainLayout>
              <Notifications />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <Logout />
            </ProtectedRoute>
          }
         />
       </Routes>
   </Router>
  );
}

export default App;
