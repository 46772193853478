import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const EditBanner = ({ itemId, onClose }) => {

    const captainData = getItemFromLocalStorage('captainProfile');
    const token = captainData.captain_access_token;
    const businessListingId = getItemFromLocalStorage('listing_id');
    const [displayPositionList, setDisplayPositionList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [image, setImage] = useState('');
    const [displayPosition, setDisplayPosition] = useState([]);
    const [category, setCategory] = useState([]);
    const [title, setTitle] = useState('');
    const [buttonLink, setButtonLink] = useState('');
    const [status, setStatus] = useState(0);
    const [isImageValid, setIsImageValid] = useState(true);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const errorRef = useRef(null);

    useEffect(() => {
        if (error && errorRef.current) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [error]);

    useEffect(() => {
        const fetchMenuList = async () => {
            if (businessListingId && token) {
                setLoading(true);
                const formData = new FormData();
                formData.append('listing_id', businessListingId);
                formData.append('token', token);

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.bannerList}`,
                        data: formData
                    });
                    if (response.data.items && Array.isArray(response.data.items)) {
                        const dataArray = response.data.items;
                        const data = dataArray.find(list => list.banner_id === itemId);
                        setImage(data.banner_image_url);
                        setTitle(data.banner_name);
                        setDisplayPosition(data.slide_list.map(item => item.id.toString()));
                        setCategory(data.category_list.map(item => item.id.toString()));
                        setButtonLink(data.banner_destination);
                        setStatus(data.banner_status);
                    }
                    else {
                        console.log('Banner list not found.');
                    }
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchMenuList();
    }, [businessListingId, token, itemId]);

    useEffect(() => {
        const fetchBaseData = async () => {
            if (businessListingId && token) {
                const formData = new FormData();
                formData.append('listing_id', businessListingId);
                formData.append('token', token);

                try {
                    const response = await axios({
                        method: 'post',
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        url: `${params.baseURL}${params.endpoints.bannerBaseData}`,
                        data: formData
                    });
                    if (response.data.status === 'ok') {
                        setDisplayPositionList(response.data.banner_slides);
                        setCategoryList(response.data.banner_category);
                    } else {
                        console.log('Banner data not found.');
                    }
                } catch (error) {
                    setError(error.message);
                }
            }
        };

        fetchBaseData();
    }, [businessListingId, token]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.src = objectUrl;

            img.onload = () => {
                if (img.width === 800 && img.height === 320) {
                    setImage(file);
                    setIsImageValid(true);
                } else {
                    toast.error('Image dimensions should be 800px x 320px.');
                    setIsImageValid(false);
                }
                URL.revokeObjectURL(objectUrl);
            };
        }
    };

    const handleDisplayPositionChange = (e) => {
        const { value, checked } = e.target;
        setDisplayPosition((prev) =>
          checked ? [...prev, value] : prev.filter((id) => id !== value)
        );
    };

    const handleCategoryChange = (e) => {
        const { value, checked } = e.target;
        setCategory((prev) =>
          checked ? [...prev, value] : prev.filter((id) => id !== value)
        );
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.checked ? 1 : 2);
    };

    const handleUpdate = async () => {

        if (!isImageValid) {
            toast.error('Image dimensions should be 800px x 320px.');
            return;
        }

        const banner_category = category.join(',');

        let formData = new FormData();
        formData.append('token', token);
        formData.append('listing_id', businessListingId);
        formData.append('banner_id', itemId);
        formData.append('banner_name', title);
        formData.append('banner_status', status);
        formData.append('banner_category', banner_category);
        formData.append('banner_destination', buttonLink);
        displayPosition.forEach((position, index) => {
            formData.append(`banner_slide_${index + 1}`, 1);
        });

        if (image && typeof image !== 'string') {
            formData.append('entry_pic', image);
        }

        setLoading(true);
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.bannerUpdateEntry}`,
                data: formData
            });

            if (response.data.status === 'completed') {
                Swal.fire({
                    icon: 'success',
                    text: 'Banner details updated successfully!',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/listing/banners';
                    }
                });
            } else if (response.data.status === 'error') {
                response.data.list.map((error) => (
                    toast.error(error)
                ));
            } else {
                setError('Error in saving data.');
            }
        } catch (err) {
            setError('An error occurred while saving data.');
        } finally {
            setLoading(false);
        }
    }


    return (
        <>
            <div className='popup'>
                <div className='popup-content mb-5'>
                {loading && <Loader />}
                {error && <div className="alert alert-danger" ref={errorRef}>{error}</div>} 
                    <div className='cpo-header' style={{ margin: '0' }}>
                        <div>
                            <h4>Edit Banner</h4>
                        </div>
                        <span className='popup-close' onClick={onClose}>&times;</span>
                    </div>
                    <div className='cposb'>
                        <form className="form-section needs-validation" encType="multipart/form-data">
                            <div className='row'>
                                <div className='col-12 mb-2'>
                                <label htmlFor="service_img" className="logo-title" style={{ cursor: 'pointer' }}>
                                    {image ? (
                                        typeof image === 'string' ? (
                                            <img src={image} alt="Profile" className="upload_img" />
                                        ) : (
                                            <img src={URL.createObjectURL(image)} alt="Profile" className="upload_img" />
                                        )
                                    ) : (
                                        <img src={'/assets/img/background/menu_banner.png'} alt="Profile" className="upload_img" />
                                    )}
                                    <input 
                                        type="file" 
                                        name="service_img" 
                                        id="service_img" 
                                        className="upload d-none" 
                                        onChange={handleImageUpload} 
                                    />
                                    <span className='image__upload_btn'>select image</span>
                                </label>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <legend class="col-form-label">Display Position</legend>
                                    <div className='radio-box'>
                                        {displayPositionList.map((type) => (
                                            <div className="form-check lserv-form-check" key={type.id}>
                                                <label className="lserv-radio-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                    <input 
                                                        name='category'
                                                        className="lserv-radio-input" 
                                                        type="checkbox" 
                                                        id={type.title.toLowerCase().replace(' ', '')} 
                                                        value={type.id}
                                                        checked={displayPosition.includes(type.id.toString())}
                                                        onChange={handleDisplayPositionChange}
                                                    />
                                                    <span className='lserv-radio-span'><em><i class="fas fa-check"></i></em>{type.title}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='col-md-12 mb-3'>
                                    <legend class="col-form-label">Category</legend>
                                    <div className='radio-box' style={{display:'inline'}}>
                                        {categoryList.map((type) => (
                                            <div className="form-check lserv-form-check" style={{display:'inline-block',marginBottom:'10px'}} key={type.id}>
                                                <label className="lserv-radio-label" htmlFor={type.title.toLowerCase().replace(' ', '')}>
                                                    <input 
                                                        name='category'
                                                        className="lserv-radio-input" 
                                                        type="checkbox" 
                                                        id={type.title.toLowerCase().replace(' ', '')} 
                                                        value={type.id}
                                                        checked={category.includes(type.id.toString())}
                                                        onChange={handleCategoryChange}
                                                    />
                                                    <span className='lserv-radio-span'><em><i class="fas fa-check"></i></em>{type.title}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                
                                <div className="col-md-12 mb-3">
                                    <div className="form-floating">
                                        <input type="text" id="title" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                        <label htmlFor="title">Title</label>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="form-floating">
                                        <input type="text" id="title" className="form-control" value={buttonLink} onChange={(e) => setButtonLink(e.target.value)} required />
                                        <label htmlFor="title">Button Link</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className='status__box'>
                                        <div>
                                            <label>Status</label>
                                        </div>
                                        <div class="toggle">                                                                                   
                                            <input 
                                                type="checkbox" 
                                                name="EditStatus" 
                                                id="EditStatus" 
                                                checked={status === 1}
                                                onChange={handleStatusChange}
                                            /> 
                                            <label for="EditStatus" style={{cursor:'pointer'}}></label>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-12 text-end'>
                                    <button type="button" className="cp-btn" onClick={handleUpdate}>SAVE</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditBanner;
