import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs from 'dayjs';

const daysOfWeek = [
  { day_id: 1, day_name: 'Monday', short_name: 'Mo' },
  { day_id: 2, day_name: 'Tuesday', short_name: 'Tu' },
  { day_id: 3, day_name: 'Wednesday', short_name: 'We' },
  { day_id: 4, day_name: 'Thursday', short_name: 'Th' },
  { day_id: 5, day_name: 'Friday', short_name: 'Fr' },
  { day_id: 6, day_name: 'Saturday', short_name: 'Sa' },
  { day_id: 7, day_name: 'Sunday', short_name: 'Su' },
];

const BusinessTimingPopup = ({ isOpen, onClose, onSelect, onDone, storedData }) => {
  const [timings, setTimings] = useState(() =>
    daysOfWeek.map((day) => ({
      ...day,
      closed: true,
      dataAvailable: false,
      timeIntervals: [{ startTime: null, endTime: null, mark_as_closed: false }],
    }))
  );
  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (storedData && Array.isArray(storedData)) {
      const updatedTimings = daysOfWeek.map((day) => {
        const storedDay = storedData.find((data) => data.day_id === day.day_id);
        return {
          ...day,
          closed: storedDay ? storedDay.closed : true,
          dataAvailable: !!storedDay,
          timeIntervals: storedDay && storedDay.dayWiseTimingData
            ? storedDay.dayWiseTimingData.map((interval) => ({
                startTime: (storedDay.closed || !interval.time_start) ? null : dayjs(parseInt(interval.time_start), 'HH:mm'),
                endTime: (storedDay.closed || !interval.time_end) ? null : dayjs(parseInt(interval.time_end), 'HH:mm'),
                mark_as_closed: interval.mark_as_closed,
              }))
            : [{ startTime: null, endTime: null, mark_as_closed: false }],
        };
      });
      setTimings(updatedTimings);
      //setLoading(false);
    }
  }, [storedData]);

  const handleCheckboxChange = (dayId, checked) => {
    setTimings((prevTimings) =>
      prevTimings.map((timing) =>
        timing.day_id === dayId
          ? {
              ...timing,
              closed: !checked,
              dataAvailable: checked,
              timeIntervals: !checked
                ? [{ startTime: null, endTime: null, mark_as_closed: false }]
                : timing.timeIntervals,
            }
          : timing
      )
    );
  };

  const handleIntervalClosedChange = (dayId, checked) => {
    setTimings((prevTimings) =>
      prevTimings.map((timing) =>
        timing.day_id === dayId
          ? {
              ...timing,
              closed: checked,
              timeIntervals: timing.timeIntervals.map((interval) => ({
                ...interval,
                startTime: checked ? null : interval.startTime,
                endTime: checked ? null : interval.endTime,
              })),
            }
          : timing
      )
    );
  };

  const handleAddInterval = (dayId) => {
    setTimings((prevTimings) =>
      prevTimings.map((timing) =>
        timing.day_id === dayId
          ? {
              ...timing,
              timeIntervals: timing.timeIntervals.length < 5 
                ? [...timing.timeIntervals, { startTime: null, endTime: null, mark_as_closed: false }]
                : timing.timeIntervals,
            }
          : timing
      )
    );
  };

  const handleDeleteInterval = (dayId, intervalIndex) => {
    setTimings((prevTimings) =>
      prevTimings.map((timing) =>
        timing.day_id === dayId
          ? {
              ...timing,
              timeIntervals: timing.timeIntervals.length > 1 && intervalIndex > 0
                ? timing.timeIntervals.filter((_, idx) => idx !== intervalIndex)
                : timing.timeIntervals,
            }
          : timing
      )
    );
  };

  const handleTimeChange = (dayId, intervalIndex, type, newValue) => {
    setTimings((prevTimings) =>
      prevTimings.map((timing) =>
        timing.day_id === dayId
          ? {
              ...timing,
              timeIntervals: timing.timeIntervals.map((interval, idx) =>
                idx === intervalIndex ? { ...interval, [type]: newValue } : interval
              ),
            }
          : timing
      )
    );
  };

  const handleDone = () => {
    const formattedData = timings
      .filter((timing) => timing.dataAvailable)
      .map((timing) => ({
        closed: timing.closed,
        current_index: 0,
        dayWiseTimingData: timing.timeIntervals.map((interval) => ({
          day_id: timing.day_id,
          mark_as_closed: interval.mark_as_closed,
          time_end: interval.endTime ? interval.endTime.format('HH:mm') : null,
          time_end_text: interval.endTime ? interval.endTime.format('h:mm A') : null,
          time_start: interval.startTime ? interval.startTime.format('HH:mm') : null,
          time_start_text: interval.startTime ? interval.startTime.format('h:mm A') : null,
        })),
        day_id: timing.day_id,
        day_name: timing.short_name,
      }));

    onSelect(formattedData);
    onDone();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="cpo-header">
          <div>
            <h4>Update Timings</h4>
          </div>
          <span className="popup-close" style={{ marginTop: '0' }} onClick={onClose}>
            &times;
          </span>
        </div>
        {
          timings.map((timing) => (
            <div key={timing.day_id} className="time-box">
              <label className="timelabel form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={timing.dataAvailable}
                  onChange={(e) => handleCheckboxChange(timing.day_id, e.target.checked)}
                />
                <span>{timing.day_name}</span>
              </label>
              <div style={{ position: 'relative' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {timing.timeIntervals.map((interval, index) => (
                    <div key={index} style={{ display: 'inline-flex', gap: '1rem', alignItems: 'center', marginBottom: '0.5rem', position: 'relative' }}>
                      <MobileTimePicker
                        value={interval.startTime}
                        onChange={(newValue) => handleTimeChange(timing.day_id, index, 'startTime', newValue)}
                        slotProps={{ textField: { fullWidth: true, inputProps: { placeholder: 'opening time' } } }}
                        disabled={timing.closed}
                      />
                      <MobileTimePicker
                        value={interval.endTime}
                        onChange={(newValue) => handleTimeChange(timing.day_id, index, 'endTime', newValue)}
                        slotProps={{ textField: { fullWidth: true, inputProps: { placeholder: 'closing time' } } }}
                        disabled={timing.closed}
                      />
                      {index > 0 && (
                        <button type="button" onClick={() => handleDeleteInterval(timing.day_id, index)} className="delete-interval-btn">
                          <i className="fas fa-minus"></i>
                        </button>
                      )}
                    </div>
                  ))}
                  {timing.dataAvailable && (
                    <button type="button" className="add-interval-btn" onClick={() => handleAddInterval(timing.day_id)}>
                      <i className="fas fa-plus"></i>
                    </button>
                  )}
                </LocalizationProvider>
              </div>
              {timing.dataAvailable && (
                <label className="macbox">
                  <input
                    className='d-none'
                    type="checkbox"
                    checked={timing.closed}
                    onChange={(e) => handleIntervalClosedChange(timing.day_id, e.target.checked)}
                  />
                  <span>{timing.closed ? 'Closed' : 'Marked as Closed'}</span>
                </label>
              )}
            </div>
          ))
        }
        <button type="button" className="done-btn" onClick={handleDone}>
          Done
        </button>
      </div>
    </div>
  );
};

export default BusinessTimingPopup;
